"use client";

import { Metadata } from "next";
import { usePathname, useRouter } from "next/navigation";
import { useEffect } from "react";
import { format } from "react-string-format";
import { NUM_PATIENTS } from "~/lib/numPatients";
import { sendPayloadToRestAPI } from "~/lib/restAPI";
import layoutMessages from "~/resources/messages/en-US/app/layout.json";
import { IFormData } from "./(normalNavbar)/(diagnostic)/api/error/route";

export const metadata: Metadata = {
	title: layoutMessages.layout.defaultPageTitle.value,
	description: format(layoutMessages.layout.defaultPageDescription.value, NUM_PATIENTS),
	openGraph: {
		title: layoutMessages.layout.defaultPageTitle.value,
		description: format(layoutMessages.layout.defaultPageDescription.value, NUM_PATIENTS),
	},
	metadataBase: new URL(process.env.NEXT_PUBLIC_BASE_SITE_URL ?? "https://localhost:3600"),
};

export default function ErrorPage({ error }: { error: Error & { digest?: string } }) {
	// Determine the path this error page is replacing and get a router for routing to a non-standard 404 page that we can control the http code returned for
	const pathname = usePathname();
	const router = useRouter();

	// Log to Splunk via a rest API that the error page was shown, then navigate to a non-standard 404 page that we can control the http code returned for
	useEffect(() => {
		async function logErrorPageShown() {
			await sendPayloadToRestAPI<IFormData>(
				{
					pathname: pathname,
					digest: error.digest,
				},
				"/api/error",
			);
		}
		void logErrorPageShown();
		router.push("/page-error");
	}, [pathname, error, router]);

	// Show the component.
	return <></>;
}

// I wish there were a standard way, but nextjs is still hard coded to have a status of 200 for streamed responses, even if some child component of that streamed response ends up generating not found or error pages.
// So following the workaround suggested here were we have a non-standard 404 and 500 page that we can control the http code returned for:
// https://github.com/vercel/next.js/discussions/53225
// See also this resource about streaming: https://nextjs.org/learn/dashboard-app/streaming
// And see this resource about how nextjs returns a 200 code for streaming responses: https://nextjs.org/docs/app/api-reference/file-conventions/not-found
